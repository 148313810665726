*, :before, :after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

body {
  margin: 0;
  background: #f6f5f5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.LoginButton svg {
  width:40px !important;
  height:auto !important;
  margin: 3px 0 0 0;
}

.zoom {
  transition:transform .2s;
}


.zoom:hover {
  transform: scale(6);
  z-index:99999999999999;
}

.searchBar > div:first-child {
  width:100%
}

.order-detail-toolbar-navigation {
  display: flex;
  flex-direction:row;
  justify-content: flex-end;
  align-items:flex-end;
  padding:0 8px 0 0;
  
}

.orderdetail-navbar {
  display:flex;
  flex-direction:row;
  background:white;
  margin:0 0 20px 0;
  padding:8px;
  justify-content: space-between;
}

.all-items-scanned {
  background-color:#d4edda !important;
  border: 2px solid #c3e6cb;
}

.one-of-many-scanned {
  background-color:#fff3cd !important;
  border: 2px solid #ffeeba;
}

.already_refunded {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  background-color: rgb(242, 70, 122);
  color: white;
  padding: 40px;
  margin: 40px 0;
}

.already_refunded .title {
  text-transform:uppercase;
}

.already_refunded .content {
  font-size:20px;
  font-weight:400;
}

.already_refunded .underlined {
  text-decoration:  underline;
}

td.item-refunded {
  position: absolute;
  box-sizing:border-box;
  top: 50%;
  left: 35%;
  transform: translate(-30%, -50%);
  padding: 10px;
  background: rgb(242, 70, 122);
  width: 44%;
  margin: auto 0;
  z-index: 9;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 30px;
  filter:blur(0px) !important;
}

.blur-tr :not(.item-refunded) {
  filter:blur(1.5px) !important;
  position:relative;
  background-color: white !important;
}


tr {
  position:relative;
}

.table-container {
  display:flex;
  flex-direction: row;
  gap:10px;
  border:1px solid #eaeaea;
  padding:5px;
}

.table-left {
  display:flex;
  flex-direction:row;
  max-width:50%;
}

.goku {
  width:70%;
}

.vegeta {
  width:50%;
}

.table-right {
  width:80%;
  display:flex;
  flex-direction:column;
}

.table-top{
  display:flex;
  flex-direction: column;
}

.table-contents {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.table-contents-left, .table-contents-right {
  max-width:50%;
  min-width:50%;
  margin-bottom:10px;
}

.qty-scanned {
  font-weight:700;
  font-size: 1.5rem;
  line-height: 2rem;
  font-color:rgb(51 65 85 / 1)
}

.table-bottom{
  margin-top:20px;
}

.information{
  border: 1px solid #dee2e6;
  box-shadow:rgba(0, 0, 0, 0.05) 0px 1px 3px 0px;
}

.name {
  font-size: 1.125rem;
  font-weight:500;
}

.color {
  margin-right:0.5rem;
}

.information-product {
  width:100%;
}

.sku {
  font-size: .875rem;
  line-height: 1.25rem;
  color:rgb(100 116 139 / 1)
}

.flex-row{
  display:flex;
  flex-direction:row;
}


.return-entry-content {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content:normal;
  justify-content: normal;
  align-items:normal;
}

.rec-ls {
  flex-basis:12rem;
  flex-shrink: 0;

}

.rec-ls img {
  object-fit:contain;
  border-radius: 0.375rem;
  max-width:100%;
  width:12rem;
  height:auto;
  margin-left:auto;
  margin-right:auto;
}

.rec-rs {
  margin-right : calc(1.5rem * 0);
  margin-left: calc(1.5rem * calc(1 - 0));
  flex: 1 1 0%;
}

.title-section {
  justify-content: space-between;
  align-items:flex-start;
  display:flex;
  margin-bottom: -0.75rem;

}

.title h3 {
  line-height: 1.5rem;
  font-weight:500;
  font-size:1.125rem;
  margin:0;
}

.title .meta {
  margin-top: calc(0.5rem * calc(1 - 0));
  margin-bottom: calc(0.5rem * 0);
  color: rgb(100 116 139 / 1);
  font-size: .875rem;
  line-height: 1.25rem;
  gap: 0.5rem;
  align-items: center;
  flex-wrap:wrap;
  display:flex;
}

.w-full {
  width:100%;
}

.flex-1 {
  flex: 1 1 0%
}

.items-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}
.flex {
  display: flex;
}

.pr-4 {
  padding-right: 1rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.border-transparent {
  border-color: transparent;
}

.border-l {
  border-left-width: 1px;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.badges {
  margin-top: calc(0.5rem * calc(1 - 0));
  margin-bottom: calc(0.5rem * 0);
  align-items:center;
  flex-wrap:wrap;
  display:flex;
}

.justify-between {
  justify-content: space-between;
}

.flex-col {
  flex-direction:column;
}

.items-end {
  align-items: flex-end;
}

.qty-title {
  color: rgb(148 163 184 / 1);
  letter-spacing:0;
  text-transform:none;
  font-size:.75rem;
  line-height:1rem;
  white-space:nowrap;
}

.qty-num {
  color: rgb(51 65 85 / 1);
  letter-spacing:0em;
  font-weight:700;
  font-size:1.5rem;
  line-height:2rem;
}

.-mb-05 {
  margin-bottom: -0.125rem;
}

.spacen {
  margin-top: calc(1rem * calc(1 - -1));
  margin-bottom: calc(1rem * 0);
}

.return-title {
  color: rgb(100 116 139 / 1);
  font-size: .875rem;
  line-height: 1.25rem;
}

.mb-2 {
  margin-bottom:.75rem;
}
